import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from './config.json';
import './App.css';
import Modal from 'react-bootstrap/Modal';

const Picture = () =>
{
    const { pictureId } = useParams();
    const [pictureData, setPictureData] = useState()
    const [emailInput, setEmailInput] = useState()
    const [nameInput, setNameInput] = useState()
    const [telInput, setTelInput] = useState()
    const [invalidEmail, setInvalidEmail] = useState()
    const [invalidName, setInvalidName] = useState()
    const [invalidTel, setInvalidTel] = useState()
    const [validatedName, setValidatedName] = useState()
    const [validatedEmail, setValidatedEmail] = useState()
    const [validatedTel, setValidatedTel] = useState()
    const [optout1, setOptout1] = useState(true)
    const [confirm1, setConfirm1] = useState()

    useEffect(() =>
    {
        fetch(config.pictureServerUrl + '/picture/' + pictureId, {
            method: 'GET',
            headers: {
                'Authorization': 'Token ' + config.token,
            },
        })
            .then((response) =>
            {
                if (response.status === 200)
                {
                    return response.blob()
                }
                /** Simply ignore any errors */
                return undefined
            })
            .then((data) =>
            {
                if (data)
                {
                    setPictureData(URL.createObjectURL(data))
                }
                else
                {
                    setPictureData(undefined)
                }
            })
            .catch((error) =>
            {
                console.log(error);
            })

    }, [pictureId])

    function log(msg)
    {
        let logUrl = config.pictureServerUrl + "/log";
        fetch(logUrl, {
            method: 'POST',
            body: "{ \"msg\": \"" + msg +"\" }",
            headers: {
                'Authorization': 'Token ' + config.token,
                'Content-Type': 'application/json'
            },
        })
            .then(res => res.json())
            .then(res => console.log(res))
            .catch((error) =>
            {
                //console.log(error);
            })
    }

    function doConfirm1()
    {
        const validEmail = String(emailInput).toLowerCase().match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
        setInvalidEmail(!validEmail);
        
        const validName = nameInput && String(nameInput).length >= 2;
        setInvalidName(!validName);

        const validTel = String(telInput).toLowerCase().match(
            /^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,6}$/im
          );
        setInvalidTel(!validTel);

        if(validEmail && validName && validTel)
        {
            setValidatedEmail(emailInput)
            setValidatedTel(telInput)
            setValidatedName(nameInput)
            log(emailInput + ";" + telInput + ";" + nameInput);
        }
        //console.log("Validate: " + validEmail + validName + validTel + optout1);
        if((validEmail && validName && validTel && !optout1) || optout1)
        {
            setConfirm1(true);
        }
        else
        {
            setConfirm1(false);
        }
        //console.log("Confirm: " + confirm1);
    }
    return (
        <div className="fullBody">
            {!pictureData && <div>Geen foto bekend met ID: {pictureId}</div>}
            
            { !((validatedEmail && validatedTel && validatedName && confirm1) || confirm1)  && pictureData &&
            <div>
            <img alt="header" className="imgHead" src="/enexis.svg"></img>
            <Modal.Dialog>
                <Modal.Header>
                <Modal.Title className="headerText">
                    <p>Wat een plaatje!</p>
                    <p>Waar mogen we je foto naartoe sturen?</p>
                </Modal.Title>
                </Modal.Header>

                <Modal.Body>           
                <label>Voor- en achternaam:</label>
                <input name="name" onInput={e => setNameInput(e.target.value)} />
                { invalidName && <p className='error formIndent'>Voer een naam in</p>}
            
                <label>E-mail:</label>
                <input name="email1" onInput={e => setEmailInput(e.target.value)} />
                { invalidEmail && <p className='error formIndent'>Ongeldig e-mail adres</p>}
            
                <label>Telefoon:</label>
                <input name="tel" onInput={e => setTelInput(e.target.value)} />
                { invalidTel && <p className='error formIndent'>Ongeldig telefoonnummer</p>}

                <p className='small' id='textStart'>Wil jij kans maken op hardloopschoenen? Schrijf je dan in voor onze winactie!</p>
                <p className='small'>Door deel te nemen geef je Enixis Groep toestemming om je via e-mail te benaderen met interessante vacatures en carrièremogelijkheiden die passen bij jouw profiel.</p>
                <label>Meedoen? Vink aan en doe mee!</label>
                <Form.Check
                    type="checkbox"
                    id={`default-checkbox`}
                    onChange={e => setOptout1(!e.target.checked)}
                />

                </Modal.Body>

                <Modal.Footer>
                <Button className="enexisButton" variant="primary" onClick={doConfirm1}>Ga naar de foto</Button>
                </Modal.Footer>
            </Modal.Dialog>
            </div>
            }
            {
                (confirm1) && pictureData &&
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    maxHeight: "100vh",
                }}>
                <div style={{
                    flex: "1",
                    maxHeight: "90vh",
                }}>
                    <img
                        src="/slogan.png"
                        alt='slogan'
                        className='slogan'/>
                    <img
                        src={pictureData}
                        alt='foto'
                        className='mainPhoto' />
                </div>
                <div style={{
                    flex: "1",
                }}>
                    <a
                        href={pictureData}
                        rel="noopener noreferrer"
                        download={pictureId}>
                        <Button className="enexisButton downloadButton" variant="primary" >
                            Downloaden
                        </Button>
                    </a>
                </div>
                </div>
            }
        </div>
    );
};
export { Picture };